import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Notifications from "react-notify-toast";
// import { renderRoutes } from 'react-router-config';
import "./App.scss";

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login/Login"));
const Register = React.lazy(() => import("./views/Pages/Register/Register"));
const ForgotPassword = React.lazy(() => import("./views/Pages/ForgotPassword/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./views/Pages/ResetPassword/ResetPassword"));
// const Page404 = React.lazy(() => import("./views/CoreUI/Pages/Page404/Page404"));
// const Page500 = React.lazy(() => import("./views/CoreUI/Pages/Page500/Page500"));
const Payment = React.lazy(() => import("./views/Payment/Payment"));
const PostRegistration = React.lazy(() => import("./views/PostRegistration/PostRegistration"));

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Notifications options={{ zIndex: 2000 }} />
        <BrowserRouter basename="/user">
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
              <Route exact path="/register" name="Register Page" render={(props) => <Register {...props} />} />
              <Route exact path="/forgot-password" name="Forgot Password Page" render={(props) => <ForgotPassword {...props} />} />
              <Route exact path="/reset-password/:token/:email" name="Reset Password Page" render={(props) => <ResetPassword {...props} />} />
              {/* <Route
                exact
                path="/postregistration/:event/:status"
                name="Post Registration Page"
                render={(props) => <PostRegistration {...props} />}
              />
              <Route exact path="/payment/:event/:type/:key/" name="Payment Page" render={(props) => <Payment {...props} />} /> */}
              {/* <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
              <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} /> */}
              <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

export default App;
